import deepWaves from '../../static/vectors/deep-waves.png';
import Container from '../../layouts/Container';
import { Box, Image, Link, Text } from '@chakra-ui/react';
import Button from '../../component/Button';
import { FaArrowRightLong } from 'react-icons/fa6';

import leftTube1 from '../../static/vectors/left-tube-1.png';
import leftTube2 from '../../static/vectors/left-tube-2.png';
import rightTube1 from '../../static/vectors/right-tube-1.png';
import rightTube2 from '../../static/vectors/right-tube-2.png';
import rightTubeMo1 from '../../static/vectors/mo-tube-1.png';
import rightTubeMo2 from '../../static/vectors/mo-tube-2.png';
import TGECountdown from "./TGECountdown";

const HeroSection = () => {
  return (
    <Box
      minH={{ base: 'auto', md: '100vh' }}
      bgImage={deepWaves}
      backgroundSize={'cover'}
      position={'relative'}
      pb={{ base: 0, md: 0 }}
    >
      <Image
        src={rightTubeMo1}
        alt={'vector'}
        position={'absolute'}
        top={0}
        right={0}
        display={{ base: 'block', md: 'none' }}
      />
      <Image
        src={rightTubeMo2}
        alt={'vector'}
        position={'absolute'}
        top={'100px'}
        right={0}
        display={{ base: 'block', md: 'none' }}
      />

      <Image
        src={leftTube1}
        alt={'vector'}
        position={'absolute'}
        bottom={0}
        display={{ base: 'none', md: 'block' }}
      />
      <Image
        src={leftTube2}
        alt={'vector'}
        position={'absolute'}
        bottom={'-100px'}
        display={{ base: 'none', md: 'block' }}
      />
      <Image
        src={rightTube1}
        alt={'vector'}
        position={'absolute'}
        bottom={0}
        right={0}
        display={{ base: 'none', md: 'block' }}
      />
      <Image
        src={rightTube2}
        alt={'vector'}
        position={'absolute'}
        bottom={'-100px'}
        right={0}
        display={{ base: 'none', md: 'block' }}
      />
      <Container>
        <Box width={'100%'} height={'100%'}>
          <Box
            maxW={{ base: '334px', md: '798px' }}
            pt={72}
            mx={'auto'}
            textAlign={{ base: 'left', md: 'center' }}
            zIndex={-1}
          >
            <Text
              fontWeight={600}
              fontFamily={'body'}
              fontSize={{ base: 42, md: 72 }}
              lineHeight={{ base: '110%', md: '100%' }}
              className={'black-gradient-text'}
              mb={4}
            >
              The leading lending <br /> market on{' '}
              <span className={'theme-gradient-text'}>L2s</span>
            </Text>
            <Text
              color={'white'}
              opacity={0.6}
              maxW={'580px'}
              mx={'auto'}
              fontSize={{ base: 18, md: 20 }}
              mb={8}
            >
              ZeroLend is the most efficient and dominant lending market on Linea,
              zkSync, Blast and Manta.
            </Text>
            <Link href={'https://one.zerolend.xyz/'} target={'_blank'}>
              <Button w={{ base: '136px', md: '232px' }}>
                Visit App &nbsp; <FaArrowRightLong />
              </Button>
            </Link>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default HeroSection;
